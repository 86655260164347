<template>
  <div id="complaintCenter">
    <div class="header">
      <div class="left">
      </div>
      <div class="right">
        <el-date-picker
            style="width: 240px"
            type="daterange"
            @change="changeTimePicker"
            @input="changeTimePickerBlur"
            is-range
            size="medium"
            :value="timeSearch"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="选择时间范围"
            value-format="timestamp"
            ></el-date-picker>
        <el-select
                   v-model="post.status"
                   style="width: 150px"
                   size="medium"
                   placeholder="请选择"
                   class="ml20 mr20">
          <el-option
                     v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"
                     ></el-option>
        </el-select>
        <!-- <el-input
            v-model="post.search"
            size="medium"
            placeholder="输入分组名称搜索"
            style="width: 218px"
        ></el-input> -->
      </div>
    </div>
    <div class="paginationBox">
      <pagination2
                   :option="post"
                   url="/complaint/complaintList"
                   ref="childDialogBox"
                   class="mt20 childDialogBox flex1"
                   @complete="complete">
        <template v-slot:default="{ tableData }">
          <el-table :header-cell-style="{
                      background: 'rgba(245,245,245,1)',
                      color: '#333333',
                    }"
                    :data="tableData">
            <el-table-column label="订单号" prop="number" width="150"></el-table-column>
            <el-table-column label="订单类型" prop="order_type">
              <template slot-scope="scope">
                {{ scope.row.order_type | formatOrderType }}
              </template>
            </el-table-column>
            <el-table-column label="投诉内容" prop="content"></el-table-column>
            <el-table-column label="描述内容" prop="describe"></el-table-column>
            <el-table-column label="投诉凭证" prop="images" width="150">
              <template slot-scope="{row}">
                <!-- <el-image style="width: 100px; height: 100px"
                          v-if="row.images&&row.images.length"
                          :src="row.images[0]"
                          :preview-src-list="row.images">
                </el-image> -->
                <el-image style="width: 50px; height: 50px;margin-right: 10px;"
                          v-for="item in row.images"
                          :key="item"
                          :src="item"
                          :preview-src-list="row.images">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="投诉时间" prop="add_time" width="180">
              <template slot-scope="scope">
                {{ scope.row.add_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column label="投诉人联系方式" prop="mobile" width="180"></el-table-column>
            <el-table-column label="处理状态" prop="status" width="100">
              <template slot-scope="scope">
                {{ scope.row.status | formatStatus }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="140">
              <template slot-scope="{ row }">
                <el-button type="text" @click="toDispose(row)">
                  修改处理状态
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </pagination2>
    </div>
  </div>
</template>

<script>
  import bg from '@/mixin/background'
  export default {
    name: 'complaintCenter',
    mixins: [bg],
    data() {
      return {
        post: {
          search:'',
          status: 0,
          sort: '',
          small_time: '',
          large_time: '',
        },
        iscomplete: false,
        tableList: [],
        selectrow: {},

        options: [
          {
            value: 0,
            label: '全部',
          },
          {
            value: 1,
            label: '待处理',
          },
          {
            value: 2,
            label: '已处理',
          },
        ],

        timeSearch: '',
      }
    },
    filters: {
      formatOrderType(item) {
        let str = ''
        switch (Number(item)) {
          case 0:
            str = '默认'
            break;
          case 1:
            str = '课程'
            break;
          case 2:
            str = '公开课'
            break;
          case 3:
            str = '会员'
            break;
          case 4:
            str = '资料'
            break;
          case 5:
            str = '知识商品'
            break;
          case 6:
            str = '图文'
            break;
          case 7:
            str = '代金券'
            break;
        }
        return str
      },
      formatStatus(item) {
        let str = ''
        switch (Number(item)) {
          case 1:
            str = '待处理'
            break;
          case 2:
            str = '已处理'
            break;
        }
        return str
      },
    },
    methods: {
      //搜索时间类型
      typeChange() {
        this.post = _.assign({}, this.post, {
          small_time: '',
          large_time: '',
        })
        this.datepicker = []
        this.timeSearch = ''
      },
      changeTimePickerBlur(val) {
        this.timeSearch = val
        this.$nextTick(() => {
          const node = document.querySelector(
            '.el-time-panel__btn.confirm[disabled="disabled"]'
          )
          node && node.removeAttribute('disabled')
        })
      },

      changeTimePicker(val) {
        console.log(val)
        if (val) {
          this.post.small_time = val[0] / 1000
          this.post.large_time = val[1] / 1000
        } else {
          this.typeChange()
        }
        // this.$refs.childDialogBox.reset()
      },
      saveLive() {
        this.$http({
          url: this.addLive.group_id ? '/liveGroup/setGroup' : '/liveGroup/addGroup',
          data: Object.assign(this.addLive, {
            name: this.addLive.group_name,
          }),
          callback: () => {
            this.closeFn()
            this.$root.prompt({
              msg: this.addLive.group_id ? '编辑成功' : '新建成功',
              type: 'success',
            })
            this.$refs.childDialogBox.reset()
          },
          error: ({ info }) => {
            this.$root.prompt({
              msg: info,
            })
          },
        })
      },
      // 处理
      async toDispose(row) {
        let str = ""
        switch (Number(row.status)) {
          case 1:
            str='是否修改状态为已处理？'
            break;
          case 2:
            str="是否修改状态为待处理？"
            break;
        }
        this.$confirm(str, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(async () => {
            await this.$http({
              url: '/complaint/changeStatus',
              data: {
                id: row.id,
                status: row.status==1?2:1,
              },
              successMsg: true,
            })
            this.$refs.childDialogBox.reset()
          })
          .catch(() => { })
      },
      // 获取列表数据
      complete(val) {
        this.tableList = val.data
        this.iscomplete = true
      },
    },
  }
</script>
<style scoped lang="scss">
#complaintCenter {
  margin: 20px;
  padding: 20px;
  background: #fff;
  min-width: 868px;

  .el-input-wrap {
    width: 518px;
  }

  .header {
    font-size: 13px;
    // padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {}
  }

  .addLive {
    width: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 82px;

    ::v-deep .el-input__inner {
      padding-right: 50px;
    }
  }
}
</style>
